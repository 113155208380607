<template>
  <b-card-actions ref="edit_card" :title="$t('Document')" action-refresh @refresh="refresh">

    <b-form @submit.prevent>
      <b-row>

        <!-- first name -->
        <b-col cols="12">
          <b-form-group
              :label="$t('description')"
              label-for="v-description"
          >
            <b-form-input
                id="v-description" :disabled="editDisabled"
                :placeholder="$t('description')"
                v-model="vehicle_document.description"
                validate="required"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <b-row>
        <b-col
            cols="12"
            md="6"
        >
          <b-form-group
              :label="$t('Delivery Date')"
              label-for="delivery_date"
          >
            <flat-pickr
                id="delivery_date"
                v-model="vehicle_document.delivery_date"
                class="form-control"
                :disabled="allEditDisabled"
                :config="{dateFormat: 'd/m/Y'}"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="6"
        >
          <b-form-group
              :label="$t('Expiration Date')"
              label-for="expiration_date"
          >
            <flat-pickr
                id="expiration_date"
                v-model="vehicle_document.expiration_date"
                class="form-control"
                :disabled="allEditDisabled"
                :config="{dateFormat: 'd/m/Y'}"
            />
          </b-form-group>
        </b-col>

      </b-row>


      <b-row>

        <b-col
            cols="6"
            md="6"
        >
          <b-form-group
              :label="$t('Certificate')"
              label-for="certificate"
          >
            <span id="certificate">{{ attachment.filename }}</span>
          </b-form-group>
        </b-col>
        <b-col cols="3" v-if="!showUploadFile">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon mt-06"
              @click.prevent.stop="enableUploadNewVersion($event)"
              :disabled="editDisabled"
          >
            <feather-icon icon="UploadIcon"/>
            Upload
          </b-button>
        </b-col>
        <b-col cols="3" v-if="!showUploadFile">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary mt-06"
              class="btn-icon button-inline-spacing-2"
              @click="download()"

          >
            <feather-icon icon="DownloadIcon"/>
            Download
          </b-button>
        </b-col>
      </b-row>
      <b-row>

        <!-- upload -->
        <b-col cols="12" v-if="showUploadFile">
          <b-form-group
              :label="$t('Upload new certificate')"

              label-for="v-upload"
          >

            <b-form-file
                id="extension"
                v-model="file"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.jpg,.png,.gif"
                :placeholder="$t('Click to upload')"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <b-row>

        <spacer height="50"></spacer>
        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1" :disabled="editDisabled"
              @click.prevent.stop="handleSubmit($event)"
          >
            {{ $t('Save') }}
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click.prevent.stop="back($event)"

          >
            {{ $t('Cancel') }}

          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card-actions>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BFormTextarea,
  BTable,
  BCardHeader,
  BCardTitle,
  BFormFile, BDropdown, BDropdownItem
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import UserService from "@/basic/userService";
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import {codeI18n} from "@/views/table/vue-good-table/code";
import {useRouter} from "@core/utils/utils";
import {BCardActions} from "@core/components/b-card-actions";
import i18n from '@/libs/i18n'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {environment} from "@/auth/environment";
import ability from "@/libs/acl/ability";
import flatPickr from 'vue-flatpickr-component'
import EquipmentService from "@/basic/equipmentService";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BFormTextarea,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardActions,
    BFormFile,
    BDropdown,
    BDropdownItem,
    flatPickr,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      vehicle_document: {description: "", delivery_date: "", expiration_date: "", id: 0},
      attachment: {filename: ""},
      allEditDisabled: false,
      permission_fields: [{key: 'name', label: i18n.t('Name'),}, {key: 'read', label: i18n.t('Read'),}, {
        key: 'write',
        label: i18n.t('Write')
      }],
      permissionsData: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      userCompany: JSON.parse(localStorage.getItem('userCompany')),
      editDisabled: !ability.can("manage", "Basic HR"),
      revisionDisabled: true,
      file: null,
      filedata: null,
      document_sections: [{id: 1, name: ""}],
      document_section_id_new: 1,
      vehicle_document_id: this.$route.params.id,
      vehicle_id: this.$route.params.vehicle_id,
      uploadNewVersion: false
    }
  },
  computed: {
    showUploadFile() {
      return this.vehicle_document_id === 'new' || this.uploadNewVersion;
    },

    isNew: function () {
      return this.vehicle_document_id === "new";
    },

    currentDocumentSection() {
      return {id: "-11", name: "beta"}
    }
  },
  methods: {
    refresh() {
      console.log("refresh vehicle id",this.vehicle_id )

      this.uploadNewVersion = false;

      const equipmentService = new EquipmentService(useJwt)
      this.$refs["edit_card"].showLoading = true

      if (this.vehicle_document_id === 'new') {
        this.vehicle_document.description = "";
        this.$refs["edit_card"].showLoading = false


      } else {

        equipmentService.vehicle_document(this.vehicle_document_id).then(response => {
          this.vehicle_document = response.data.data[0]
          console.log('vehicle_document', this.vehicle_document)
          this.attachment = this.vehicle_document.attachment;
          this.attachment.filename = this.attachment.path.split('/').pop();
          console.log('filename', this.attachment.filename)
          this.$refs["edit_card"].showLoading = false

        })
      }


    },

    enableUploadNewVersion(section) {
      this.uploadNewVersion = true;

    },
    download() {
      const link = document.createElement("a");
      link.href = environment.baseURL + "/documents/download_attachment/" + this.vehicle_document.attachment.id + "/" + this.vehicle_document.attachment.otp
      link.download = this.vehicle_document.attachment.path;
      link.click();
      URL.revokeObjectURL(link.href);

    },
    updateDocument: function (data) {
      const equipmentService = new EquipmentService(useJwt)

      equipmentService.update_vehicle_document(this.vehicle_document_id, data).then(response => {
        if (response.data.status === "success") {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('Document updated'),
              icon: 'AlertIcon',
              variant: 'success',
              text: i18n.t('Document saved with success'),
            },
          })
          this.$router.push({name: 'vehicle', params: {id: this.vehicle_id, tab:'vehicle_documents'}})

        } else {


          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('Generic error'),
              icon: 'AlertIcon',
              variant: 'danger',
              text: i18n.t('There was an error saving the document, please retry'),
            },
          })
          this.$refs["edit_card"].showLoading = true

        }


      })
    },
    async back(e) {
      this.$router.push({name: 'vehicle', params: {id: this.vehicle_id, tab:'vehicle_documents'}})

    },
    async handleSubmit(e) {

      const equipmentService = new EquipmentService(useJwt)

      this.$refs["edit_card"].showLoading = true

      this.submitted = true;

      console.log('handleSubmit file', this.file)
      console.log('handleSubmit document_section_id_new', this.document_section_id_new)
      console.log('handleSubmit uploadNewVersion', this.uploadNewVersion)
      console.log('handleSubmit document', this.vehicle_document)
      console.log('handleSubmit vehicle_document_id', this.vehicle_document_id)


      this.vehicle_document.document_section_id = this.document_section_id_new
      const data = {
        vehicle_document: this.vehicle_document,
        company: this.userCompany,
        uploadNewVersion: this.uploadNewVersion,
        vehicle_id: this.vehicle_id
      };


      if (this.file) {


        let filevalue = this.file;
        console.log('handleSubmit filevalue', filevalue)

        var filePath = filevalue.name;
        console.log('handleSubmit filePath', filevalue.name)


        let reader = new FileReader();

        reader.onload = (e) => {
          console.log('readAsDataURL', e.target.result);
          this.filedata = e.target.result
          const data_upload = {
            filedata: this.filedata,
            filename: filePath
          };
          const userService = new UserService(useJwt)


          userService.upload_attachment(this.userCompany.id, data_upload).then(response => {
            if (response.data.status === "success") {
              console.log("response", response.data)

              this.vehicle_document.attachment_id = response.data.data.attachment.id
              this.updateDocument(data);

            } else {

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: i18n.t('Generic error'),
                  icon: 'AlertIcon',
                  variant: 'danger',
                  text: i18n.t('There was an error saving the attachment, please retry'),
                },
              })
            }
            this.refresh();
          })
        };
        reader.readAsDataURL(this.file);


      } else {
        this.updateDocument(data);
      }


      this.$refs["edit_card"].showLoading = false
    },
  },
  mounted() {
    this.refresh()
    //this.$http.get('/good-table/basic')
    //.then(res => { this.rows = res.data })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
